<template>
    <div>
        <p class="title text-center">{{ $t('uni_settings.title') }}</p>
        <div class="row">
          <v-card v-for="(uniSetting,i) in universesSettings" :key="i" max-width="220" max-height="80" :style="styleObject">
            <v-row dense justify="end" style="padding: 0 10px 0px;"
              v-if="isAdministrator && isLicenseValid">
              <button v-on:click="deleteItem(uniSetting)" >x</button>
            </v-row>
            <v-col v-else></v-col>
            <v-card-text style="padding: 0 5px 0px;">
              <v-row dense>
                  <v-col md="3">
                    <v-row dense align="center" justify="center">
                      <h2>{{uniSetting.number}}</h2>
                    </v-row>
                    <v-row dense v-text="portAddressView(uniSetting.number)" align="center" justify="center">

                    </v-row>
                  </v-col>
                  <v-col md="8">
                  <v-select
                      v-model="uniSetting.device"
                      :items="artNetDevicesForSelect"
                      item-text="name"
                      item-value="uniSetting.device.name"
                      @change="editUniverse(uniSetting)"
                      :label="$t('uni_settings.device_label')"
                      dense
                      :readonly="!(isAdministrator && isLicenseValid)"
                  ></v-select>
                  </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-if="isAdministrator && isLicenseValid"
            max-width="220"
            width="150"
            max-height="80"
          >
              <v-card-text d-flex>
              <v-row dense>
                  <v-col>
                  <v-dialog v-model="dialog" max-width="500px" id="2">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          x-large
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                      >
                          mdi-plus-circle</v-icon>
                      </template>
                      <v-card>
                          <v-card-title>
                              <span class="headline">{{ $t('uni_settings.add_universe_card.title') }}</span>
                          </v-card-title>
          
                          <v-card-text>
                            <v-form v-model="isValid">
                              <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                      v-model="editedItem.number"
                                      :error-messages="uniFormErrors.number"
                                      label="number"
                                      :rules="[rules.uniRange, rules.mustBeInt, uniUnique]"
                                      required
                                      :readonly="isEdit"
                                  ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                  <v-select
                                      v-model="editedItem.device"
                                      :error-messages="uniFormErrors.device"
                                      :items="artNetDevices"
                                      item-text="name"
                                      :label="$t('uni_settings.add_universe_card.artnet_label')"
                                  ></v-select>
                                  </v-col>
                              </v-row>
                              <v-row v-text="portAddress">
                              </v-row>
                            </v-form>
                            <v-row class="error-message red--text" v-if="uniFormErrors.non_field_errors.length != 0">
                              <span
                                class="mb-7 ml-4" 
                                v-for="(error, index) in uniFormErrors.non_field_errors" :key="index"
                                v-text="error + ' '"
                              />
                            </v-row>
                          </v-card-text>
          
                          <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary--text" @click="close">
                                {{ $t('uni_settings.add_universe_card.cancel_button') }}
                              </v-btn>
                              <v-btn :disabled="!isValid" color="primary--text" @click="save">
                                {{ $t('uni_settings.add_universe_card.save_button') }}
                              </v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  </v-col>
                  <v-col>
                    <v-row>{{ $t('uni_settings.add_button') }}</v-row>
                    <v-row>{{ $t('uni_settings.universe_button') }}</v-row>
                  </v-col>
              </v-row>
              </v-card-text>
          </v-card>
        </div>
    </div>
</template>


<script>
export default {
  name: 'uniSettings',
  data: () => ({
    styleObject: { border: '2px solid gold'},
    renderKey: 0,
    isValid: true,
    uniFormErrors: {
      number: [],
      device: [],
      non_field_errors: [],
    },
    dialog: false,
    editedIndex: -1,
    editedItem: {'number': 0, 'device': null},
    defaultItem: {'number': 0, 'device': null},
    universesSettingsData: [],
  }),
  computed: {
    rules() {
      return {
        uniRange: value => parseInt(value) <= 32768 && parseInt(value) >= 0 || this.$t('uni_settings.rules.uni_range'),
        mustBeInt: value => Number.isInteger(Number(value)) || this.$t('uni_settings.rules.must_be_integer'),
      }
    },
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    isEdit () {
      return this.editedIndex != -1
    },
    universesSettings() {
      return this.$store.getters.getUniversesSettings
    },
    artNetDevices() {
      return this.$store.getters.getArtNetDevicesSettings
    },
    artNetDevicesForSelect() {
      let devices
      devices = [...this.$store.getters.getArtNetDevicesSettings]
      devices.push({name: '----------'})
      return devices
    },
    portAddress() {
      let number = parseInt(this.editedItem.number)
      
      let uniByte = (number).toString(2).padStart(16, '0')
      let net = parseInt(uniByte.slice(0, 8), 2)
      let subNet = parseInt(uniByte.slice(8, 12), 2)
      let universe = parseInt(uniByte.slice(12), 2)

      return this.$t('uni_settings.add_universe_card.port_address') + ` ${net}.${subNet}.${universe}`
    }
  },
  methods: {
    uniUnique (value) {
      for (let uniSetting of this.universesSettings) {
        if (uniSetting['number'] == value) {
          return this.$t('uni_settings.rules.must_be_unique')
        }
      }
      return true
    },
    editUniverse (item) {
      if (item.device == '----------') {item.device = null}
      this.$store.dispatch('editUniverse', item)
    },
    deleteItem (item) {
      this.$store.dispatch('deleteUniverse', item)
      this.close()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$forceUpdate()
    },
    save () {
      this.$store.dispatch('addUniverse', this.editedItem)
      .then(() => this.close())
      .catch( errors => {
        console.log(errors)
        Object.assign(this.uniFormErrors, errors)
      })
    },
    portAddressView(universe_number) {
      let number = parseInt(universe_number)
      
      let uniByte = (number).toString(2).padStart(16, '0')
      let net = parseInt(uniByte.slice(0, 8), 2)
      let subNet = parseInt(uniByte.slice(8, 12), 2)
      let universe = parseInt(uniByte.slice(12), 2)

      return `${net}.${subNet}.${universe}`
    },
  },
  watch: {
    universesSettings: {
      deep: true,
      handler: function (uniSettings) {
        this.universesSettingsData = uniSettings
      }
    },
    dialog (val) {
      val || this.close()
    },
  },
  mounted() {
    this.$store.dispatch('updateUniversesSettings')
  },
  beforeUpdate() {
    this.renderKey += 1
  },
}
</script>