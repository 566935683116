<template>
  <div class="script">
      <uniSettings />
      <br />
      <br />
      <artNetDeviceSettings />
  </div>
</template>

<script>
import uniSettings from '@/components/uniSettings';
import artNetDeviceSettings from '@/components/art_net_device_settings';

export default {
  name: 'adsfasdf',
  components: {
      artNetDeviceSettings,
      uniSettings,
    },
}
</script>