<template>
  <div>
    <div>
      <p class="title text-center">{{ $t('artnet_device_settings.title') }}</p>
      <v-data-table
        :headers="artNetDeviceTableHeaders"
        :items="artNetDevices"
        :key="renderKey"
        :no-data-text="$t('table_empty')"
        item-key="name"
        :footer-props="{
          'items-per-page-options': [20],
          'disableItemsPerPage': false,
          pageText: $t('table_footer.page_text')
        }"
        class="elevation-1"
      >
        <template v-slot:top> 
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('artnet_device_settings.table_title') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdministrator && isLicenseValid"
                  class="mb-2 primary--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('artnet_device_settings.add_artnet_device_button') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
    
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          :label="$t('artnet_device_settings.edit_device_card.name_label')"
                          required
                          :readonly="isEdit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.network_mode"
                          :items="networkModeOptions"
                          :label="$t('artnet_device_settings.edit_device_card.network_mode_label')"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.ip"
                          :label="$t('artnet_device_settings.edit_device_card.ip_address')"
                          :rules="[rules.ip]"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.port"
                          :label="$t('artnet_device_settings.edit_device_card.port')"
                          :rules="[rules.portRange]"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.description"
                          :label="$t('artnet_device_settings.edit_device_card.description')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-2 primary--text"
                    @click="close"
                  >
                    {{ $t('artnet_device_settings.edit_device_card.cancel_button') }}
                  </v-btn>
                  <v-btn
                    :disabled="!isValid"
                    class="mb-2 primary--text"
                    @click="save">
                    {{ $t('artnet_device_settings.edit_device_card.save_button') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{ $t('artnet_device_settings.delete_device_card.title') }}?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-2 primary--text"
                    @click="closeDelete"
                  >{{ $t('artnet_device_settings.delete_device_card.cancel_button') }}</v-btn>
                  <v-btn
                    class="mb-2 primary--text"
                    @click="deleteItemConfirm"
                  >{{ $t('artnet_device_settings.delete_device_card.ok_button') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'artNetDeviceSettings',
  data: () => ({
    renderKey: 0,
    isValid: true,
    dialog: false,
    dialogUniverse: false,
    dialogDelete: false,
    editedIndex: -1,
    editedUniverse: {'number': 0, 'device': null},
    defaultUniverse: {'number': 0, 'device': null},
    editedItem: {
      name: '',
      network_mode: 'broadcast',
      ip: '',
      port: 0,
      description: '',
    },
    defaultItem: {
      name: '',
      network_mode: 'broadcast',
      ip: '',
      port: 6454,
      description: '',
    },
  }),
  computed: {
    rules() {
      return {
      required: value => !!value || this.$t('artnet_device_settings.rules.required'),
      portRange: value => parseInt(value) < 65535 && parseInt(value) > 0 || this.$t('artnet_device_settings.rules.port_range'),
      ip: value => {
        const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        return pattern.test(value) || this.$t('artnet_device_settings.rules.invalid_ip')
        }
      }
    },
    artNetDeviceTableHeaders() {
      return [
      { text: this.$t('artnet_device_settings.table_headers.name'), value: 'name' },
      { text: this.$t('artnet_device_settings.table_headers.network_mode'), value: 'network_mode' },
      { text: this.$t('artnet_device_settings.table_headers.ip'), value: 'ip' },
      { text: this.$t('artnet_device_settings.table_headers.port'), value: 'port' },
      { text: this.$t('artnet_device_settings.table_headers.description'), value: 'description' },
    ]},
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    isEdit () {
      return this.editedIndex != -1
    },
    formTitle () {
      return !this.isEdit ? this.$t('artnet_device_settings.edit_device_card.title_new_device') : this.$t('artnet_device_settings.edit_device_card.title_edit')
    },
    universesSettings() {
      return this.$store.getters.getUniversesSettings
    },
    artNetDevices() {
      return this.$store.getters.getArtNetDevicesSettings
    },
    networkModeOptions() {
      return this.$store.getters.getNetworkModeOptions
    },
  },
  methods: {
    addUniverse () {
      this.$store.dispatch('createUniverse', this.editedUniverse)
    },
    editItem (item) {
      this.editedIndex = this.artNetDevices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.artNetDevices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$forceUpdate()
    },
    save () {
      if (this.editedIndex > -1) {
        this.$store.dispatch('editArtNetDevice', this.editedItem)
      } else {
        this.$store.dispatch('createArtNetDevice', this.editedItem)
      }
      this.close()
    },
    deleteItemConfirm () {
      this.$store.dispatch('deleteArtNetDevice', this.editedItem.name)
      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created() {
    if (this.$store.getters.isAdministrator && this.isLicenseValid) {
      this.artNetDeviceTableHeaders.push(
        { text: this.$t('artnet_device_settings.table_headers.actions'), value: 'actions', sortable: false }
      )
    }
  },
  mounted() {
    this.$store.dispatch('updateArtNetDevicesSettings')
  },
  beforeUpdate() {
    this.renderKey += 1
  },
}
</script>